import React, { useEffect, useRef, useState } from 'react';
interface Props {
  dataUid?: string;
}
const ConvertKit = ({ dataUid }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const scriptEl = useRef(null);
  //Only add the script when component mounts
  useEffect(() => {
    if(typeof window !== 'undefined') {
      const n = Math.random().toString(36).substring(7)
      const convertKitScript = document.createElement('script');
      // convertKitScript.id = dataUid;
      convertKitScript.async = true;
      convertKitScript.onload = () => {
        window.trackingFunctions.onLoad({appId: "663117d88b91ff0300e75986"})
      };

      // convertKitScript.setAttribute('data-uid', dataUid); //e0da3595ff
      convertKitScript.src = 'https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=' + n
      // @ts-ignore
      scriptEl.current.appendChild(convertKitScript);
    }
  }, []);
  return <div ref={scriptEl} />;
};

export default ConvertKit;
