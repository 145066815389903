import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../../components/DefaultLayout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Map from '../../components/Map';
import Img from 'gatsby-image';
import Sidebar from '../../components/Sidebar';
import LatestPosts from '../../components/LatestPosts';
import HomePage from '../../components/HomePage';
import Testimonials from '../../components/Testimonials';
import { WhyUsPage } from '../../components/WhyUsPage';
import ConvertkitButton from '../../components/ConvertkitButton';
import ConvertKit from '../../components/ConvertKit';
import GetStarted from '../../components/GetStarted';
import { Services } from '../../components/services/Services';
import { CoreValues } from '../../components/CoreValues/CoreValues';

// @ts-ignore
export default ({ data }) => {
  const { page, post, wpPage } = data;
  const { title, content, featuredImage, excerpt, databaseId, isFrontPage, slug, parentId } = page;
  const { nodes } = post;
  const isFrontPageWithPosts = isFrontPage && nodes.length > 0;
  const hasSidebar = parentId === 'cG9zdDoyMTE=';
  const isContactPage = slug === 'contact';
  const isServices = slug === 'services';
  const isTradesPage = slug === 'construction-trades';
  const isWhyUsPage = slug === 'why-us';
  const isMeetTheTeamPage = slug === 'meet-the-team';
  const isBookkeepingServicePage = slug === 'online-bookkeeping-vancouver';
  const isCorporateServicePage = slug === 'corporate-income-tax-vancouver';
  const isVCFOServicePage = slug === 'virtual-cfo-and-coaching';
  const isPersonalTaxServicePage = slug === 'personal-income-tax-vancouver';
  return (
    <DefaultLayout>
      <ConvertKit />
      <Seo post={wpPage} />
      {isContactPage ? (
        <section className='map w-full'>
          <Map />
        </section>
      ) : null}
      <div className={`container mx-auto px-6 lg:px-20 ${hasSidebar ? 'flex flex-col lg:flex-row' : ''}`}>
        <article
          className={`post-${databaseId} ${slug} ${isFrontPage ? 'front-page' : ''} ${
            hasSidebar ? 'sidebar w-full lg:w-2/3 overflow-hidden' : ''
          } post page type-page status-publish hentry`}
          id={`post-${databaseId}`}
        >
          {/*TODO: put this back for sites that use the main heading in posts*/}
          {/*{!isFrontPage &&*/}
          {/*<header className="pt-8">*/}
          {/*  <h1*/}
          {/*    className="entry-title text-6xl font-bold text-center prose max-w-none"*/}
          {/*    dangerouslySetInnerHTML={{ __html: title }}*/}
          {/*  />*/}
          {/*</header>*/}
          {/*}*/}

          {featuredImage && (
            <div>
              {/*<Img placeholderClassName={'w-full'} fluid={featuredImage.node.localFile.childImageSharp.fluid} />*/}
            </div>
          )}

          <div className='post-inner thin max-w-screen-lg m-auto'>
            {isFrontPageWithPosts ? (
              <>
                <HomePage content={content} />
                <LatestPosts isFrontPageWithPosts={isFrontPageWithPosts} nodes={nodes} />
                {/*<ConvertKit dataUid='e0da3595ff' />*/}
              </>
            ) : isTradesPage ? (
              <>
                <div
                  className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className='flex justify-center my-16 mx-auto '>
                  <a
                    className='flex justify-around header-cta rounded-xl text-white text-lg items-center bg-secondary-base hover:bg-secondary-base text-sm py-3 px-2 font-sans tracking-wide font-normal'
                    rel='noopener noreferrer'
                    href='https://info.argentocpa.ca/levelup'
                  >
                    <svg className='w-6 h-6 fill-current text-white inline-block' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
                      <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
                    </svg>
                    Level up your trades business guide
                  </a>
                </div>
                <Testimonials page={isTradesPage} />
                <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
                <div className='flex justify-center my-16 mx-auto '>
                  <a
                    className='flex justify-around header-cta rounded-xl text-white text-lg items-center bg-secondary-base hover:bg-secondary-base text-sm py-3 px-2 font-sans tracking-wide font-normal'
                    rel='noopener noreferrer'
                    href='/contact-us/'
                  >
                    <svg className='w-6 h-6 fill-current text-white inline-block' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
                      <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
                    </svg>
                    Lets chat
                  </a>
                </div>
                {/*<ConvertKit dataUid='e0da3595ff' />*/}
              </>
            ) : isWhyUsPage ? (
              <>
                <WhyUsPage content={content} />
                {/*<ConvertKit dataUid='e0da3595ff' />*/}
              </>
            ) : isServices ? (
              <>
                <Services />
                <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
                {/*<ConvertKit dataUid='e0da3595ff' />*/}
              </>
            ) : isBookkeepingServicePage ||
              isCorporateServicePage ||
              isVCFOServicePage ||
              isPersonalTaxServicePage ||
              isMeetTheTeamPage ? (
              <>
                <div
                  className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                {isMeetTheTeamPage && (
                  <>
                    <CoreValues />
                  </>
                )}
                {isBookkeepingServicePage && (
                  <>
                    <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
                    {/*<ConvertKit dataUid='e0da3595ff' />*/}
                  </>
                )}
                {isCorporateServicePage && (
                  <>
                    <ConvertkitButton formkitToggle='3bb4d19760'>Looking for tax tips and tricks?</ConvertkitButton>
                    {/*<ConvertKit dataUid='3bb4d19760' />*/}
                  </>
                )}
                {isVCFOServicePage && (
                  <>
                    <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
                    {/*<ConvertKit dataUid='e0da3595ff' />*/}
                  </>
                )}
                {isPersonalTaxServicePage && (
                  <>
                    <ConvertkitButton formkitToggle='e0da3595ff'>Looking for tax tips and tricks?</ConvertkitButton>
                    {/*<ConvertKit dataUid='e0da3595ff' />*/}
                  </>
                )}
              </>
            ) : (
              <div
                className='entry-content prose max-w-none mt-0 lg:mt-8 lg:mb-16'
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {hasSidebar ? (
              <div className='max-w-md mx-auto pb-4'>{/* Services page Typekit <ContactForm />*/}</div>
            ) : null}
          </div>
        </article>
        {hasSidebar ? (
          <article className={`w-full lg:w-1/3 lg:pl-16`}>
            <Sidebar />
          </article>
        ) : null}
      </div>
      {isFrontPage ? (
        <section className='map w-full'>
          <Map />
        </section>
      ) : null}
      {isContactPage ? (
        <div className='max-w-md mx-auto pb-4'>
          <GetStarted />
          {/*<ContactForm />*/}
        </div>
      ) : null}
    </DefaultLayout>
  );
};

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    post: allWpPost(limit: 2, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        date(formatString: "LL")
        excerpt
        link
        uri
      }
    }
    page: wpPage(id: { eq: $id }) {
      ...PageContentSecond
    }
    wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;
