export const StrategyLandingPageData = [
  {
    text: `Working with Michael is one of the best decisions I've made for my business. He takes his work and his clients seriously. I use Argento CPA for all my accounting and tax needs. He actually listened to what I needed and got everything perfectly caught up and he did it fast. Highly recommended.`,
    name: 'Stephen Chauvette',
    title: '',
    link: 'https://www.dealermedia.com/',
    company: 'Dealer Media',
    transformation: 'From under $100k/month to $1M/month in less than three years',
  },
  {
    text: `As the owner of Wicks Electric, the highest reviewed electrical contractor in our beautiful province of BC, customer service and diligence is EVERYTHING to me. Argento CPA has been my accountant basically since I started the company. Michael has been an absolute pleasure to work with and he has come up with some accounting strategies that have saved me both time and money. I recommend this beauty to every person I can. HIGHLY RECOMMEND, he is 100% worth switching accountants for. `,
    name: 'Adam Wicks',
    title: '',
    link: 'https://wickselectric.ca/',
    company: 'Wicks Electric',
    transformation: 'From under $100k to $6M',
  },
  {
    text: `Michael is the best in the business. His attention to detail and client service is outstanding. We’ve been working with Argento for 3 years now with 2 of our corporate businesses for monthly bookkeeping as well as our personal and corporate taxes. I highly recommend Michael and his team.`,
    name: 'Drew Urquhart',
    title: '',
    link: 'https://banchmarketing.com/',
    company: 'Banch Marketing',
    transformation: 'From $20k/month to over $200k/month in less than two years',
  },
];

export const StrategyLandingPageDataB = [
  {
    text: `It’s such a good framework, and it’s changed my motivation in the business. I really feel like you guys did all the work, which is awesome because it was something that I could take on even when I didn't have a lot of time. Michael has helped me build a stronger foundation for my business and set my sights on what success looks like for my company, with concrete steps to get there. He will help you level up. If you can work with this firm, don't hesitate!`,
    name: 'Jennifer Blodgett',
    title: '',
    link: '#',
    company: 'JMB Ventures',
    transformation: '7-figure entrepreneur',
  },
  {
    text: `Michael and his team at Argento CPA have been an incredible aid in the development of our new small
                business. It's meeting and connecting with business owners like Argento CPA, and their network, that
                give us confidence to grow and expand, even in uncertain times. Highly recommended.`,
    name: 'Tim Managh',
    title: 'Owner',
    link: 'https://www.alignclimate.com/',
    company: 'Align Climate Solutions',
    transformation: 'From $40k/month to $200k/month in less than two years',
  },
  {
    text: `Everything was straightforward, and the process was way quicker than I thought it was going to be.`,
    name: 'Darin Shierbeck',
    title: '',
    link: 'https://kempharvey.com/',
    company: 'KH Kelowna Chartered Accountants',
    transformation: '7-figure firm',
  },
];

export const faqs = [
  {
    question: 'Am I eligible for the Canada Digital Adoption Program?',
    answer:
      'You need to be incorporated federally or provincially, or be a Canadian resident sole proprietor, ' +
      'be a for-profit, privately owned business, ' +
      'have between 1 – 499 full time equivalent employees, ' +
      'have at least $500,000 of annual revenues in one of the previous three tax years',
  },
  {
    question: 'How much funding is available and when does the program end?',
    answer:
      'The Canada Digital Adoption Program (CDAP) has committed a hefty $4 billion to foster digital transformation among small to medium-sized businesses and the program ends in 2026 or when all the funds are used up.',
  },
  {
    question: 'Can I go through this program without working with an advisor?',
    answer:
      'No, you need to work with an approved digital advisor certified under the Canada Digital Adoption Program.',
  },
  {
    question: 'How long does it take to create a Canada Digital Adoption Plan?',
    answer: 'Our process takes about 8 weeks from start to finish.',
  },
  {
    question: 'How much time do I need to commit to creating my Canada Digital Adoption Plan?',
    answer:
      'Our framework allows us to go through the process as quickly as possible to maximize the return on investment of your valuable time.  You only need to commit about 1 hour per week for 8 weeks.',
  },
  {
    question: 'I own multiple companies.  Can apply for multiple grants?',
    answer: 'Yes.  You can apply for each company as long as they all meet the eligibility criteria.',
  },
  {
    question: 'Will Argento CPA help implement my digital adoption plan?',
    answer:
      'Yes.  Our aim is to over-deliver on our business plans and provide as much value to our clients so that we can grow through retention and referrals.  We’ll work closely with you on implementation.  Plus, we’re part of a Digital Maturity Group with access to experts in every industry if we need to bring on help with implementation.',
  },
];
