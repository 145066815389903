import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { observer } from 'mobx-react-lite';
import Img from 'gatsby-image';
import UniversalLink from './UniversalLink';
import CpaLogo from './CpaLogo';

const Sidebar: React.FC = observer(() => {
  return (
    <div className='flex flex-col items-center lg:mt-16 mx-auto m-0'>
      <CpaLogo />
      <ul className='sidebar-services-nav'>
        <li>
          <UniversalLink activeClassName='active' to={`/services/virtual-cfo-and-coaching/`}>
            Virtual CFO and Coaching
          </UniversalLink>
        </li>
        <li>
          <UniversalLink activeClassName='active' to={`/services/personal-income-tax-vancouver/`}>
            PERSONAL INCOME TAX
          </UniversalLink>
        </li>
        <li>
          <UniversalLink activeClassName='active' to={`/services/corporate-income-tax-vancouver/`}>
            Corporate Income Tax
          </UniversalLink>
        </li>
        <li>
          <UniversalLink activeClassName='active' to={`/services/tax-accountant-vancouver/`}>
            Accounting
          </UniversalLink>
        </li>
        <li>
          <UniversalLink activeClassName='active' to={`/services/online-bookkeeping-vancouver/`}>
            Online Bookkeeping
          </UniversalLink>
        </li>
        <li>
          <UniversalLink activeClassName='active' to={`/services/technology-consulting-service/`}>
            Technology Consulting
          </UniversalLink>
        </li>
      </ul>
    </div>
  );
});

export default Sidebar;
