import React from 'react';

interface Props {}

const GetStarted: React.FC<Props> = () => {
  return (
    <div className='flex justify-center p-6 mb-4'>
      <a
        className='text-center header-cta rounded-xl text-white text-sm bg-secondary-base hover:bg-secondary-base text-sm py-3 px-2 font-sans tracking-wide font-normal'
        rel='noopener noreferrer'
        href='/contact-us/'
      >
        Book a FREE Strategy Call
      </a>
    </div>
  );
};

export default GetStarted;
