import React from 'react';

export const CoreValues = () => {
  return (
    <div className='home-page entry-content prose max-w-none mt-0 lg:mt-0 lg:mb-4 wp-block-group'>
      <div className='values-section'>
        <h2 className='home-heading-light meet-the-team-heading'>Our Core Values</h2>
        <ul className='services-list'>
          <li>
            <strong>Collaboration</strong> – Emphasizes the power of working together towards a common purpose. It
            involves actively seeking and valuing diverse perspectives and fostering a culture of inclusivity, trust,
            and open communication. Working together to make a win-win situation. This means listening to each other and
            optimizing our work relationships to reach high levels of success as a team. The best teams analyze their
            strategy and the effectiveness of their efforts. With that, you should grow with your team where everyone
            helps each other get better.
          </li>
          <li>
            <strong>Kaizen</strong> – A word used in Japanese businesses that means constant improvement. This principle
            is based on gradual enhancements that compound over time and enable us to achieve high levels of success. If
            we can get just 1% better each day, the compounding effect is tremendous. With consistent innovation, you
            will become adaptable to even the most difficult situations.
          </li>
          {/*<li className='no-bullet'>*/}
          {/*  With consistent innovation, you will become adaptable to even the most difficult situations.*/}
          {/*</li>*/}
          <li>
            <strong>Humility</strong> – Not always assuming you are right, or you have the best approach to issues.
            Willingness to listen, learn, and acknowledge the contributions of others, valuing their perspectives and
            expertise. Embracing humility as a core value fosters collaboration, open-mindedness, and genuine respect
            for the dignity and worth of all individuals, promoting a harmonious and inclusive work environment.
          </li>
          {/*<li className='no-bullet'>*/}
          {/*  Take ownership of your team’s failure and learn from your mistakes. Pain and frustration are emotional*/}
          {/*  signals that indicate a change is needed or a process is missing. Welcome failure as your opportunity to*/}
          {/*  adjust your approach, improve, and try again.*/}
          {/*</li>*/}
          {/*<li className='no-bullet'>You will never fail if you keep trying.</li>*/}
          <li>
            <strong>Ownership</strong> – Taking ownership of the work you are assigned. This means staying accountable
            to do what you say you are going to do, and speaking up if it's not something you can handle. Also taking
            ownership of your team’s failure and learning from your mistakes. Pain and frustration are emotional signals
            that indicate a change is needed or a process is missing. Welcome, failure as your opportunity to adjust
            your approach, improve, and try again. You will never fail if you keep trying.
          </li>
          {/*<li className='no-bullet'>*/}
          {/*  A coach must understand your ambitions and goals and co-create an action plan to get you to where you want*/}
          {/*  to be.*/}
          {/*</li>*/}
        </ul>
      </div>
    </div>
  );
};
