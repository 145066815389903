import React from 'react';
import Testimonials from '../Testimonials';
import { Cta } from '../CDAP/CdapLandingPage';
import { faqs, StrategyLandingPageData, StrategyLandingPageDataB } from './StrategyLandingPageData';
// @ts-ignore
import SC from '../../assets/images/landing/sc.png';
// @ts-ignore
import AW from '../../assets/images/landing/aw.png';
// @ts-ignore
import DU from '../../assets/images/landing/du.png';
// @ts-ignore
import MA from '../../assets/images/landing/ma.png';
// @ts-ignore
import JG from '../../assets/images/landing/jg.png';
// @ts-ignore
import JS from '../../assets/images/landing/js.png';
// @ts-ignore
import MAB from '../../assets/images/landing/ma-about.png';
// @ts-ignore
import LOGOS from '../../assets/images/landing/logos/logos.png';
// @ts-ignore
import GoogleLogo from '../../assets/images/landing/logos/credentials-logos.png';
// @ts-ignore
import MobileLogos from '../../assets/images/landing/logos/mobile-logos.png';
import { AccordionItem } from '../AccordionItem';

interface SectionHeadingProps {
  heading: string;
  headingTwo?: string;
  headingThree?: string;
  footnote?: string;
  subheading?: string;
  cta?: boolean;
  ctaText?: string;
  customClass?: string;
  invert?: boolean;
}

export const SectionHeading = ({
  heading,
  headingTwo,
  headingThree,
  footnote,
  subheading = '',
  cta = false,
  ctaText,
  customClass,
  invert = false,
}: SectionHeadingProps) => {
  return (
    <div className={`${customClass} why-us-section wp-block-group ${invert ? '' : 'sections__container'}`}>
      <div className='flex flex-col gap-12'>
        <h2 className={`${invert ? 'home-heading-black text-dark-base' : 'home-heading-white text-white'}`}>
          {heading}
        </h2>
        {headingTwo && (
          <h2 className={`${invert ? 'home-heading-black text-dark-base' : 'home-heading-white text-white'}`}>
            {headingTwo}
          </h2>
        )}
        {headingThree && (
          <h2 className={`${invert ? 'home-heading-black text-dark-base' : 'home-heading-white text-white'}`}>
            {headingThree}
          </h2>
        )}
      </div>
      {cta && ctaText && (
        <div className='flex flex-col items-center pt-8'>
          <Cta text={ctaText} />
        </div>
      )}
      {footnote && (
        <div className={`reset-prose mt-6 ${invert ? '' : 'light'}`}>
          <p className='font-bold text-center text-2xl'>{footnote}</p>
        </div>
      )}
    </div>
  );
};
export const StrategyLandingPage = () => {
  return (
    <div className='flex flex-col items-center pb-16 pt-8'>
      {/*<SectionHeading*/}
      {/*  customClass='mt-0'*/}
      {/*  heading='Helping entrepreneurs access a $15k CDAP grant and $100k 0% interest loan through the Canada Digital Adoption Program'*/}
      {/*/>*/}
      <h2 className='home-heading-super-bold'>Get a $15,000 CDAP grant</h2>
      <Cta text='Get your $15,000 grant now' />
      <div className='reset-prose mt-6 tight'>
        <p className='font-bold text-center text-dark-base'>Limited spots available.</p>
        <p className='font-bold text-center text-dark-base'>
            The CDAP grant covers 90% of the costs to create your strategic planning playbook.  You may also qualify for a loan from BDC of $100k to implement your plan.
        </p>
      </div>
      <SectionHeading heading='Argento CPA is a team of expert digital advisors and business coaches.' headingTwo='No one else has all these credentials.' />
      <div className='flex flex-col gap-16 items-center mb-12'>
        <img src={GoogleLogo} alt='5 Star Google Reviews' className='lg:block hidden' />
        <img src={MobileLogos} alt='5 Star Google Reviews' className='lg:hidden' />
        <h2 className='home-heading-super-bold'>
          To get your $15,000 grant and $100,000 0% loan you must work with an approved CDAP advisor.
        </h2>
        <Cta text='Work with the only company in the world that has all these credentials' />
        <SectionHeading
          heading='Our success rate is 100% with all our applications and we guarantee results.'
          headingTwo='There is zero risk when applying for CDAP with Argento CPA.'
          cta={true}
          ctaText='Apply for CDAP with zero risk now!'
        />
        <h2 className='home-heading-super-bold'>
          Argento CPA helps entrepreneurs apply for the Canada Digital Adoption Program.
        </h2>
        <div className='flex flex-row lg:flex-col'>
          <div className='flex testimonials flex-col lg:flex-row flex-wrap justify-around w-full'>
            <img className='mb-0' src={SC} alt='SC' />
            <img className='mb-0' src={AW} alt='AW' />
            <img className='mb-0' src={DU} alt='DU' />
          </div>
          <Testimonials noTitle={true} testimonials={StrategyLandingPageData} />
        </div>
        <Cta text='Book a FREE strategy call from the company with complete 5-star reviews' />
      </div>
      <div className='why-us-section mt-0 wp-block-group sections__container mb-0'>
        <div className='flex flex-col text-center items-center justify-between'>
          <span className='text-white text-4xl mb-8'>
            Prime interest rates are 7.2% and cash business loans are up to 12%. Are you prepared for the oncoming
            economic challenges in your business?
          </span>
          <span className='text-white text-4xl mb-8'>
            As business owners ourselves, we understand how economic uncertainty can feel.
          </span>
          <span className='text-white text-4xl mb-8'>
            That’s why we’ll help you leverage the Canada Digital Adoption Program to future-proof your business to stay
            competitive and maximize profitability while getting you access to $100k 0% interest cash and a $15k CDAP
            grant.
          </span>
          <Cta text='Get your $100k 0% interest loan now' />
        </div>
      </div>
      <h2 className='home-heading-bold reset-prose text-center'>TRUSTED BY BUSINESSES WE SERVE</h2>
      <div className='reset-prose'>
        <img className='my-64' src={LOGOS} alt='Logos' />
      </div>
      <SectionHeading
        heading='In just 3 months, we’ve helped our clients access over $3,000,000 in government funding with minimal effort on their end.  Our approach is designed to deliver results quickly and painlessly.'
        cta={true}
        ctaText='Access government funding with minimal effort'
      />
      <SectionHeading
        heading='Are you stuck wondering exactly how the Canada Digital Adoption Program (CDAP) could help your business?'
        headingTwo='You don’t have to waste time spending countless hours on the web trying to figure this out yourself while the probability of you figuring this out on your own is not guaranteed.'
        invert={true}
      />
      <SectionHeading
        heading='Or you could get on a quick call with us, we’ll do it for you, and it’ll take you only 30 minutes to get pre-approved.'
        footnote='As a bonus – by the time we’re done with our call, we’ll help you unlock 3 areas of growth in your business.'
        cta={true}
        ctaText='Get approved for CDAP in 30 minutes'
      />
      <h2 className='home-heading-bold text-center'>We help you avoid:</h2>
      <div className='flex flex-col lg:flex-row lg:gap-16'>
        <ul className='services-list avoid'>
          <li>Costly mistakes</li>
          <li>Business failure</li>
        </ul>
        <ul className='services-list avoid'>
          <li>Profit leakage</li>
          <li>Cash flow issues</li>
        </ul>
        <ul className='services-list avoid'>
          <li>Inefficient processes</li>
          <li>Failed execution of business plans</li>
        </ul>
      </div>
      <div className='green-check-list-section flex flex-col items-center content-center gap-4 mb-8'>
        <h2 className='home-heading-bold'>What results can I expect from working with Argento CPA?</h2>
        <ul className='services-list eligible self-start lg:ml-16'>
          <li>Gain access to a $15k CDAP grant and $100k 0% interest loan.</li>
          <li>Working with CPAs, Gallup-certified coaches, and approved CDAP digital advisors.</li>
          <li>Clear roadmap to achieve your goals.</li>
          <li>Better business decisions.</li>
          <li>Increase in productivity.</li>
          <li>Unlimited support to troubleshoot your problems.</li>
          <li>Bullet-proof business plan to help you thrive through any economic storm.</li>
        </ul>
        <Cta text='Get these guaranteed results today' />
      </div>
      <div className='green-check-list-section flex flex-col items-center content-center gap-4 mb-16'>
        <h2 className='home-heading-bold'>How is Argento CPA different from other CDAP advisors?</h2>
        <ul className='services-list eligible self-start lg:ml-16'>
          <li>
            Chartered Professional Accountants who look at your Canada Digital Adoption Plan from a finance perspective
            and deep understanding of cloud accounting processes and tech stacks.
          </li>
          <li>CDAP Advisors with backgrounds in process optimization, cyber security, and IT support.</li>
          <li>
            Gallup-Certified Executive Business Coaches who understand how to build a strong culture, foster efficient
            teams, recruit rockstar talent, and leadership.
          </li>
          <li>Experts in sales process, logistics, warehousing, distribution, and project management.</li>
          <li>100+ clients in industries including but not limited to, construction, e-commerce, and SaaS.</li>
          <li>
            We go above and beyond to deliver a Canada Digital Adoption Plan that you can use to maximize growth and
            profitability in your business. We don’t stop working until you are 100% satisfied with our results.
          </li>
        </ul>
        <Cta text='Work with Argento CPA on your Canada Digital Adoption Plan' />
      </div>
      <h2 className='base-heading-bold text-center'>How Our Canada Digital Adoption Program Works</h2>
      <h2 className='base-heading-bold text-center reset-prose'>
        1. Free Strategy Call to get pre-approved for CDAP $15k grant. <br />
        <br />
        2. We’ll work with you to create a strategic plan to help you systemize and scale your business.
        <br />
        <br />
        3. You get access to a $100k 0% interest loan and $7,300 wage subsidy.
      </h2>
      <Cta text='Access your $15k CDAP grant, $100k 0% interest loan, and $7,300 wage subsidy here' />
      <div className='mb-24 mt-12'>
        <Testimonials noTitle={true} testimonials={StrategyLandingPageDataB} />
      </div>
      <div className='mb-10'>
        <Cta />
      </div>
      <SectionHeading heading='Our Process' />
      <ul className='services-list eligible self-start lg:ml-16'>
        <li>
          <strong>Assessment</strong> – We do a full 360-degree assessment of your business and ask all the right
          questions to uncover your pain points which are creating bottlenecks in your business and leaking profits so
          you will know exactly what to focus on.
        </li>
        <li>
          <strong>Strategy</strong> – We determine 3 strategic themes and create solutions to your biggest problems. We
          identify your highest priorities and develop a detailed timeline, budget, and actionable steps to move your
          business forward.
        </li>
        <li>
          <strong>Action</strong> – Plans are meaningless without proper execution. We work with you to adapt and
          execute your plans so that your business enables you to live the ideal lifestyle you deserve.
        </li>
      </ul>
      <div className='mb-10'>
        <Cta text='Work with Argento CPA now' />
      </div>
      <SectionHeading heading='Our Transformation Team' />
      <div className='flex flex-row lg:flex-col'>
        <div className='flex testimonials flex-col lg:flex-row justify-around gap-20'>
          <div className='transformation flex flex-col items-center text-center'>
            <img className='mb-0 w-40' src={MA} alt='MA' />
            <div className=''>
              <h3 className='lg-heading-bold'>Michael Argento</h3>
              <span className='text-dark-base text-xl block m-auto text-center mb-12'>Finance and Reporting</span>
            </div>
          </div>
          <div className='transformation flex flex-col items-center text-center'>
            <img className='mb-0 w-40' src={JG} alt='JG' />
            <div className=''>
              <h3 className='lg-heading-bold'>Jordy Guillon</h3>
              <span className='text-dark-base text-xl block m-auto text-center mb-12'>
                Systems, Automation, Security
              </span>
            </div>
          </div>
          <div className='transformation flex flex-col items-center text-center'>
            <img className='mb-0 w-40' src={JS} alt='JS' />
            <div className=''>
              <h3 className='lg-heading-bold'>Jared Scott</h3>
              <span className='text-dark-base text-xl block m-auto text-center mb-12'>
                Sales and Process Optimization
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center my-32 reset-prose'>
        <h2 className='lg-heading-bold text-center'>About Michael Argento</h2>
        <span className='text-dark-base text-xl block m-auto text-center mb-12'>Founder of Argento CPA</span>
        <div className='flex flex-col lg:flex-row lg:gap-12 gap-1 lg:items-start mb-12 reset-prose'>
          <img className='mb-0 lg:self-start mb-img' src={MAB} alt='About Michael' />
          <p className='text-3xl m-0'>
            Michael is the founder of Argento CPA and a dedicated entrepreneur. He’s worked with hundreds of business
            owners as they scaled their companies from under 6 figures in revenue to 8 figures and understands how the
            continual development of people, processes, and offers plays a major role in the health of an organization.
            He has a deep passion for helping other entrepreneurs achieve their ideal lifestyles and make breakthroughs
            in their businesses.
          </p>
        </div>
        <Cta text='Apply for CDAP with Argento CPA here' />
      </div>
      <div className='flex flex-col items-center mb-8 mt-0 reset-prose'>
        <h2 className='lg-heading-bold text-center'>Frequently asked questions</h2>
        <p className='text-xl m-0'>
          Ready to embrace the Canada Digital Adoption Program, but unsure about the initial steps? We're here to
          simplify the usual complexities, explain the benefits that CDAP can provide, and demonstrate how Argento CPA
          can guide you through each stage of the process.
        </p>
        <ul className='accordion'>
          {faqs.map((faq, index) => (
            <AccordionItem key={index} faq={faq} />
          ))}
        </ul>
      </div>
    </div>
  );
};
