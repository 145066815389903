import React from 'react';
import Testimonials from './Testimonials';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import GetStarted from './GetStarted';
import { SectionHeading } from './StrategyLandingPage/StrategyLandingPage';
// @ts-ignore
import GoogleLogo from '../assets/images/landing/logos/credentials-logos.png';
// @ts-ignore
import MobileLogos from '../assets/images/landing/logos/mobile-logos.png';
import { Cta } from './CDAP/CdapLandingPage';
// @ts-ignore
import LOGOS from '../assets/images/landing/logos/logos.png';
// @ts-ignore
import SC from '../assets/images/landing/sc.png';
// @ts-ignore
import AW from '../assets/images/landing/aw.png';
// @ts-ignore
import DU from '../assets/images/landing/du.png';
import { StrategyLandingPageData } from './StrategyLandingPage/StrategyLandingPageData';
import { ProcessSection } from './ThreeStepProcess/ThreeStepProcess';

const HomePage = ({ content }: any) => {
  const { heroHome, advisory } = useStaticQuery(graphql`
    {
      heroHome: file(relativePath: { eq: "images/hero-home.png" }) {
        childImageSharp {
          gatsbyImageData(width: 720, height: 480, quality: 100)
        }
      }
      advisory: file(relativePath: { eq: "images/advisory.png" }) {
        childImageSharp {
          #          fixed(width: 974, height: 224, quality: 100) {
          #            ...GatsbyImageSharpFixed
          #          }
          fluid(maxWidth: 974, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  const hero = getImage(heroHome.childImageSharp.gatsbyImageData);
  return (
    <>
      <div className='home-page wp-block-group entry-content prose max-w-none mt-0 lg:mb-4 wp-block-group'>
        <div className='sections__container less-padding flex flex-col gap-4'>
          <h2 className='text-white'>
            Save 10 Hours Every Week and <br />
            Boost Your Profits with Expert Fractional CFO Services.
          </h2>
          <p className='text-center'>Control Your Money, Enjoy Your Life</p>
        </div>
        <div className='header-section landing-page wp-block-group testimonials__container'>
          <div className='flex items-center justify-center -mt-2 -mb-2'>
            {hero && <GatsbyImage image={hero} alt='Argento CPA' className='home-hero-image mx-auto' />}
          </div>
        </div>
        <SectionHeading
          customClass='sub-hero'
          heading='Argento CPA is a Canadian online accounting firm that offers bookkeeping, controllership, and fractional CFO support for Digital Marketing Agencies, IT MSPs, and Cybersecurity Consultants.'
        />
        <GetStarted />
        <div>
          <p className='text-xl font-bold text-center'>Limited spots available.</p>
        </div>
        <div className='wp-block-group testimonials__container'>
          <h2>This is what some of our clients had to say about us:</h2>
        </div>
        <div className='flex flex-row lg:flex-col mb-12'>
          <div className='flex testimonials flex-col lg:flex-row flex-wrap justify-around w-full'>
            <img className='mb-0' src={SC} alt='SC' />
            <img className='mb-0' src={AW} alt='AW' />
            <img className='mb-0' src={DU} alt='DU' />
          </div>
          <Testimonials noTitle={true} testimonials={StrategyLandingPageData} />
        </div>

        <div className='flex flex-col gap-16 items-center mb-12'>
          <img src={GoogleLogo} alt='5 Star Google Reviews' className='lg:block hidden' />
          <img src={MobileLogos} alt='5 Star Google Reviews' className='lg:hidden' />
          <Cta text='Book a FREE strategy call from the company with complete 5-star reviews' to={`/contact-us/`} />
        </div>
        <div className='why-us-section wp-block-group sections__container'>
          <div className='flex flex-col gap-12'>
            <h2 className='home-heading-white text-white'>Do you know where your business makes money?</h2>
          </div>
        </div>
        <div className='modern-day'>
          <p>You’re busy running your company, making big decisions, and planning for growth.</p>
          <p>As the leader, your job is to grow your business and make it more successful.</p>
          <p>It’s frustrating when your accountant doesn’t help you understand your money.</p>
          <p>
            <strong>
              <i>You didn’t get into business to be an accountant.</i>
            </strong>
          </p>
          <p>Every business owner should get help from their CPA, not be held back.</p>
          <p>Your finances should use technology, be simple, and give you the important numbers right away.</p>
          <p>
            We understand you’re looking for a partner who can help you make strategic decisions, automate processes,
            and plan.
          </p>
          <p>With our help, you’ll make decisions backed by solid data, not gut feelings.</p>
          <p>You'll stop worrying about cash flow, pay less tax, and sell your business for a lot of money.</p>

          <h2>Five questions to see if your startup will scale or go stale…</h2>
          <ol>
            <li>Are you worried about paying too much tax to the CRA?</li>
            <li>Is your tech company getting all the grants and funding it can?</li>
            <li>Do you understand your cash flow, MRR, CAC, and LTV clearly?</li>
            <li>Do you have a clear plan to grow your business and eventually sell it?</li>
            <li>Are you using the latest technology to make your finance process easier?</li>
          </ol>
          {/*<p>*/}
          {/*  These are the foundations to systemize and scale your business successfully so that you can grow to*/}
          {/*  7-figures and 8-figures and beyond.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  <strong>We’ve helped many businesses</strong>, including ourselves, with this exact problem and have the*/}
          {/*  experience and resources to guide owners in the right direction to systemize and scale.*/}
          {/*</p>*/}
        </div>
        <SectionHeading heading='We Are Helping You Transform Your Business' />
        <p>
          We’ll take you from being a traditional professional, bogged down by outdated systems, constantly playing
          catch-up, and losing opportunities to more agile competitors.
        </p>
        <p>
          To be an innovative business leader, leveraging cutting-edge tools and streamlined processes, driving growth,
          and setting industry standards for efficiency and scalability.
        </p>
        <GetStarted />
        <h2 className='home-heading-bold reset-prose text-center'>TRUSTED BY BUSINESSES WE SERVE</h2>
        <div className='reset-prose'>
          <img className='my-64' src={LOGOS} alt='Logos' />
        </div>
        <GetStarted />
        <ProcessSection />
        <h2 className='home-heading-bold text-center'>We help you avoid:</h2>
        <div className='flex flex-col lg:flex-row lg:gap-16 lg:ml-24'>
          <ul className='services-list avoid'>
            <li>Costly mistakes</li>
            <li>Business failure</li>
          </ul>
          <ul className='services-list avoid'>
            <li>Profit leakage</li>
            <li>Cash flow issues</li>
          </ul>
          <ul className='services-list avoid'>
            <li>Inefficient processes</li>
            <li>Failed execution of business plans</li>
          </ul>
        </div>
        <div className='green-check-list-section flex flex-col items-center content-center gap-4 mb-8'>
          <h2 className='home-heading-bold'>What can I get from working with Argento CPA?</h2>
          <ul className='services-list eligible self-start lg:ml-16'>
            <li>Always available support with a promise to respond in one day.</li>
            <li>Find and get grants and funding.</li>
            <li>Save more money with smart tax plans.</li>
            <li>See your finances anytime, so you always know how your business is doing.</li>
            <li>Use automation to save time and avoid mistakes.</li>
            <li>A team of CPAs that help you grow your business and sell it for the most money.</li>
          </ul>
          <p>
            Take the first step towards financial clarity and growth. Contact us today to schedule your 90-minute
            Accounting Transformation Challenge. Don’t let financial problems hold you back any longer.
          </p>
          <Cta to={`/contact-us/`} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
