import React from 'react';
// @ts-ignore
import discover from '../../assets/images/landing/process/discover.png';
// @ts-ignore
import design from '../../assets/images/landing/process/design.png';
// @ts-ignore
import deliver from '../../assets/images/landing/process/deliver.png';

const ProcessCard = ({ title, description, imgSrc }) => {
  return (
    <div className='process-card bg-white shadow-md rounded-md overflow-hidden'>
      {imgSrc && <img src={imgSrc} alt={title} className='w-full h-60 object-cover mb-0' />}
      <div className='p-4 pt-0'>
        <div className='flex items-center justify-center space-x-2 mb-4'>
          <h3 className='text-lg font-bold'>{title}</h3>
        </div>
        <p className='text-gray-600 font-medium text-center text-dark-base'>{description}</p>
      </div>
    </div>
  );
};

export const ProcessSection = () => {
  return (
    <div className='process-section why-us-section wp-block-group sections__container'>
      <div className='flex flex-col gap-3'>
        <h5 className='small-heading text-center'>WHAT WE CAN DO FOR YOU</h5>
        <h2 className='main-heading text-2xl font-bold mb-6'>Our Process</h2>
        <p className='process-copy pb-6'>
          On average, we save each of our 100+ clients 10 hours every week and help them make more money.
        </p>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
          <ProcessCard
            imgSrc={discover}
            // icon='🔍'
            title='Discover'
            description='Learn about your accounting and challenges.'
          />
          <ProcessCard
            imgSrc={design}
            // icon='✏️'
            title='Design'
            description='Check your records for mistakes, see if you can get loans or grants, and be ready for CRA review.
Know where your money goes and make sure your plans and budgets are accurate.'
          />
          <ProcessCard
            imgSrc={deliver}
            // icon='🚚'
            title='Deliver'
            description='Make a special plan to fix what needs improving.
Give easy steps to make your accounting better.'
          />
        </div>
      </div>
    </div>
  );
};
