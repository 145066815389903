import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { observer } from "mobx-react-lite";
import Img from "gatsby-image";


const Map: React.FC = observer(() => {
  const { staticMap } = useStaticQuery(graphql`
      query StaticMapQuery {
          staticMap: staticMap {
              childFile {
                  childImageSharp {
                      fluid(maxWidth: 2000, quality: 100) {
                          # or fixed
                          ...GatsbyImageSharpFluid
                      }
                  }
              }
          }
      }`);
  const canGetMapQuery = staticMap !== undefined;
  return (
    canGetMapQuery ? (<Img
      fluid={staticMap.childFile.childImageSharp.fluid}
      alt="Google Map"
      className="map"
      fadeIn={false}
    />) : null
  );
});

export default Map;

