import React, { ReactNode } from 'react';
import UniversalLink from './UniversalLink';

interface Props {
  isFrontPageWithPosts: boolean;
  nodes: [];
}

const LatestPosts: React.FC<Props> = ({ isFrontPageWithPosts, nodes }) => {
  return (
    <section className='front-page-latest-posts'>
      <h3 className='text-3xl font-normal text-primary-base'>
        Latest News<span className='heading-border hidden lg:relative lg:block'> </span>
      </h3>
      <div className='flex flex-wrap overflow-hidden lg:-mx-2 xl:-mx-2 my-6'>
        {isFrontPageWithPosts &&
          nodes.map((node: { excerpt: string; date: string | number | Date; title: any; uri: string }, i: number) => {
            const cleanExcerpt = node.excerpt.replace(/[[\]\\]/g, '');
            return (
              <article
                key={node.title}
                className={`${
                  i === 0 ? 'most-recent-post border-solid lg:border-r border-primary-base' : ''
                } front-page-post w-full overflow-hidden pb-6 lg:pb-0 lg:my-2 lg:px-2 lg:w-1/2 xl:my-2 xl:px-2 xl:w-1/2`}
              >
                <h4 className='mb-4 text-2xl text-primary-base font-bold'>
                  <UniversalLink to={node.uri}>
                    <span dangerouslySetInnerHTML={{ __html: node.title }} />
                  </UniversalLink>
                </h4>
                <p className='mb-4 text-secondary-base' dangerouslySetInnerHTML={{ __html: node.date.toString() }} />
                <span className='mb-4' dangerouslySetInnerHTML={{ __html: cleanExcerpt }} />
                <button className='w-40 bg-white hover:bg-secondary-base text-secondary-base border-solid border-2 border-secondary-base hover:text-white rounded-sm my-4'>
                  <UniversalLink to={node.uri}>Read Blog Post</UniversalLink>
                </button>
              </article>
            );
          })}
      </div>
    </section>
  );
};

export default LatestPosts;
